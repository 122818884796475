
import React, { Component } from 'react';
import { slide as Menu } from 'react-burger-menu'
import { AiOutlineClose, AiOutlineMenu } from 'react-icons/ai';
import { Link } from 'gatsby';
import headerJsonConfig from '../../config/mega-menu-config';
import { renderMegaMenuTabs } from './Header';

const HamburgerMenu = class HamburgerMenu extends Component {
  // TODO read through library docs and see if we can just delete this
  showSettings(event) {
    event.preventDefault();
  }

  render () {
    // NOTE: You also need to provide styles, see https://github.com/negomi/react-burger-menu#styling
    return (
      <Menu right disableAutoFocus
        className="hamburger-menu-container"
        burgerButtonClassName={ "burger-menu-closed" }
        customCrossIcon={<AiOutlineClose />}
        customBurgerIcon={ <AiOutlineMenu/> }
      >
        <Link to="/"
              className="relative block py-4 px-4 text-base font-bold text-palletpink">Home</Link>
        <Link to="/company"
              className="relative block py-4 px-4 text-base font-bold text-palletpink">Company</Link>
        <Link to="/contact"
              className="relative block py-4 px-4 text-base font-bold text-palletpink">Contact</Link>
        {renderMegaMenuTabs(headerJsonConfig)}
      </Menu>
    );
  }
}

export default HamburgerMenu;
