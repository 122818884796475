import React from 'react';
import { Link } from 'gatsby';

const Footer = () => (
  <footer className="container mx-auto">
  <section className="container mx-auto items-center py-2 px-3 mb-8 text-gray-800">

    <div className="flex -mx-3">
      <div className="flex-1 px-3">
        <h2 className="text-lg font-semibold">About Us</h2>
        <ul className="mt-4 leading-loose">
          <li>
            <Link to="/company">Company</Link>
          </li>
          <li>
            <Link to="/contact">Contact Us</Link>
          </li>
        </ul>

      </div>
      <div className="flex-1 px-3">
        <h2 className="text-lg font-semibold">Important Links</h2>
        <ul className="mt-4 leading-loose">
          <li>
            <Link to="/terms-of-use">Terms of Use</Link>
          </li>
          <li>
            <Link to="/privacy-policy">Privacy Policy</Link>
          </li>
          <li>
            <Link to="/confidential-reporting/">Confidential Reporting</Link>
          </li>
        </ul>
      </div>
      <div className="flex-1 px-3">
        <h2 className="text-lg font-semibold">Social Media</h2>
        <ul className="mt-4 leading-loose">
          <li>
            <a href="https://www.linkedin.com/company/digitalfinancial">LinkedIn</a>
          </li>
          <li>
            <a href="https://twitter.com/DiFiLLC">Twitter</a>
          </li>
          <li>
            <a href="https://github.com/digitalfinancial">GitHub</a>
          </li>
        </ul>
      </div>
    </div>
  </section>
    <p className="mt-5">© Copyright {new Date().getFullYear()}, Digital Financial, LLC. All Rights Reserved</p>
  </footer>
);

export default Footer;
