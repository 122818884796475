import React from 'react';
import Link from 'gatsby-link';
import LogoIcon from '../../images/logos/DigitalFinanceLogoWithSpacing.png';
import headerJsonConfig from '../../config/mega-menu-config'
import { kebabCase, truncate } from 'lodash';
import { useMediaQuery } from 'react-responsive';

import HamburgerMenu from './HamburgerMenu';

const Header = () => {
  const isMobilePanelView = useMediaQuery({
    query: '(max-device-width: 640px)'
  });

  return (
    <header className="top-0 bg-white border-b border-gray-300 shadow">
      <nav className="relative bg-white text-gray-900 mx-auto">
        <div className="container mx-auto flex justify-between">
          <div className="relative block pl-0 pr-4 header-logo-container text-xl text-blue-600 font-bold">
            <Link to="/">
              <div className="flex items-center text-2xl">
                <div className="w-48 mr-3">
                  <img src={LogoIcon}/>
                </div>
              </div>
            </Link>
          </div>

          {!isMobilePanelView &&  <ul className="flex">
            <li className="hover:bg-splashblue hover:text-palletpink">
              <Link to="/" className="relative block py-4 px-4 lg:py-4 text-sm lg:text-base font-bold hover:text-palletpink text-gray-900">Home</Link>
            </li>

            {renderMegaMenuTabs(headerJsonConfig)}

            <li className="hover:bg-splashblue hover:text-palletpink">
              <Link to="/company" className="relative block py-4 px-4 lg:py-4 text-sm lg:text-base font-bold hover:text-palletpink text-gray-900">Company</Link>
            </li>
            <li className="hover:bg-splashblue hover:text-palletpink">
              <Link to="/contact" className="relative block py-4 px-4 lg:py-4 text-sm lg:text-base font-bold hover:text-palletpink text-gray-900">Contact</Link>
            </li>

          </ul> }
          {
            isMobilePanelView && <HamburgerMenu />
          }

        </div>
      </nav>
    </header>
  );
};

export const renderMegaMenuTabs = (menuConfig) => {
    return (menuConfig.map((tab) => tabWithLevels(tab)))
}

const megaMenuNestedColumns = (columnConfig) => {
  const { columnHeader, childLinks } = columnConfig;

  //TODO lg:w-1/3 => w-1/4 when we add another section
  return(
    <ul

      className="sm:w-1/3 sm:border-gray-600 sm:border-b sm:border-r lg:border-b-0 pb-6 sm:pt-6 lg:pt-3 megamegu-column"
      key={kebabCase(columnHeader)}
    >
      <h3 className="px-4 sm:block sm:font-bold sm:text-xl hover:text-palletpink text-bold mb-2">{columnHeader}</h3>
      { childLinks.map((cl) => {
        return(
          <li key={kebabCase(cl.title)} className="px-4 mega-menu-nested-link">
            <Link to={'/' + (cl.page || kebabCase(cl.title))} className="block py-1 text-gray-300 hover:text-white hover:container sm:text-lg">
              {truncate(cl.title, { length: 42, separator: /,? +/ })}
            </Link>
          </li>
        )

      }) }

    </ul>
  )
}
const tabWithLevels = (hierarchyConfig) => {
  let { topLabel, childColumns } = hierarchyConfig;

  return (
    <li className="hoverable hover:bg-splashblue hover:text-palletpink megamenu-top-tab" key={kebabCase(topLabel)}>
      <label className="block cursor-pointer py-4 px-4 lg:py-4 text-sm lg:text-base font-bold megamenu-top-tab">{topLabel}</label>
      <div role="toggle" className="p-6 mega-menu mb-16 sm:mb-0 shadow-xl bg-splashblue">
        <div className="container mx-auto w-full sm:flex sm:flex-wrap justify-between mx-2">

          {
            childColumns.map((ccol) => megaMenuNestedColumns(ccol))
          }
        </div>
      </div>
    </li>
  )
}

export default Header;
