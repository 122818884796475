const megaMenuConfig = [
  {
    topLabel: 'Capabilities',
    childColumns: [
      {
        columnHeader: 'People',
        childLinks: [
          {
            title: 'Portfolio Managers'
          },
          {
            title: 'Advisors'
          },
          {
            title: 'Analysts'
          },
          {
            title: 'Accounting & Finance'
          },
          {
            title: 'Data Scientists'
          },
          {
            title: 'Investors'
          },
          {
            title: 'Engineers & Developers'
          },
          {
            title: 'Architects & Technologists'
          },
          {
            title: 'Risk Management Professionals'
          }
        ]
      },
      {
        columnHeader: 'Organizations',
        childLinks: [
          { title: 'Banks & Trusts' },
          { title: 'Advisory Firms' },
          { title: 'Family Offices' },
          { title: 'Funds' },
          { title: 'Pensions' },
          { title: 'Endowments & Foundations' },
          { title: 'Brokerage Firms' },
          { title: 'Sovereign Wealth Funds' }
        ]
      },
      {
        columnHeader: 'Offerings',
        childLinks: [
          {
            title: 'Content & Data'
          },
          {
            title: 'Analytics'
          },
          {
            title: 'APIs',
            page: 'apis'
          },
          {
            title: 'Transparency'
          },
          {
            title: 'Financial Data Management'
          }
        ]
      }
    ]
  }
  // ,
  // {
  //   topLabel: 'Partners',
  //   childColumns: [
  //     {
  //       columnHeader: 'People',
  //       childLinks: [
  //         {
  //           title: 'Investors'
  //         },
  //         {
  //           title: 'Wealth Managers'
  //         },
  //         {
  //           title: 'Financial Advisors'
  //         },
  //         {
  //           title: 'Legal Advisors'
  //         },
  //         {
  //           title: 'Fund Managers'
  //         },
  //         {
  //           title: 'Technologists'
  //         },
  //         {
  //           title: 'Data Scientists'
  //         },
  //         {
  //           title: 'Analysts'
  //         }
  //       ]
  //     },
  //     {
  //       columnHeader: 'Organizations',
  //       childLinks: [
  //         { title: 'Individuals' },
  //         { title: 'Family Offices' },
  //         { title: 'Advisory Firms' },
  //         { title: 'Pensions' },
  //         { title: 'Foundations' },
  //         { title: 'Endowments' },
  //         { title: 'Sovereign' },
  //         { title: 'Private Banks' },
  //         { title: 'Trust Companies' },
  //         { title: 'Broker/Dealers' }
  //       ]
  //     },
  //     {
  //       columnHeader: 'Offerings',
  //       childLinks: [
  //         {
  //           title: 'Content & Data'
  //         },
  //         {
  //           title: 'Analytics'
  //         },
  //         {
  //           title: 'Transparency'
  //         },
  //         {
  //           title: 'Financial Data Management'
  //         }
  //       ]
  //     },
  //     {
  //       columnHeader: 'Bright Lights',
  //       childLinks: [
  //         {
  //           title: 'Multi Currency'
  //         },
  //         {
  //           title: 'Global Reach'
  //         },
  //         {
  //           title: 'Performance'
  //         },
  //         {
  //           title: 'Common Lingua Franca'
  //         },
  //         {
  //           title: 'Machine Readable'
  //         },
  //         {
  //           title: 'Case Studies'
  //         }
  //       ]
  //     },
  //   ]
  // }
];

export default megaMenuConfig;
