import React from 'react';
import {Helmet} from 'react-helmet'

import Footer from './Footer';
import Header from './Header';
import difiLogoIcon from '../../images/logos/difiLogoIcon.png';

const Layout = ({ children }) => {
  return (
    <>
      <Header />
      <Helmet>
        <link rel="icon" type="image/png" href={difiLogoIcon} />
      </Helmet>
      <main className="text-gray-900">{children}</main>
      <Footer />
    </>
  );
};

export default Layout;
